<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
              :src="require('@/assets/images/logo/logo.png')"
              alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            ERP
            <small>
              <i>by HMT</i>
            </small>
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Welcome') }} 👋
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('PleaseSignInToYourAccount') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
            ref="loginForm"
            #default="{invalid}"
        >
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent
          >

            <!-- email -->
            <b-form-group
                label-for="email"
                :label="$t('Email')"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ capitalize($t('password')) }}</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>{{ $t('ForgotPassword?') }}</small>
                </b-link>
              </div>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click.native="togglePasswordVisibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
                class="mt-2"
                @click="login"
            >
              {{$t("SignIn")}}
            </b-button>
          </b-form>
        </validation-observer>

<!--        <b-card-text class="text-center mt-2">-->
<!--          <span>New on our platform?</span>-->
<!--          <b-link :to="{name:'auth-register'}">-->
<!--            <span>Create an account</span>-->
<!--          </b-link>-->
<!--        </b-card-text>-->


      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@/utils/validations/validations'
import { togglePasswordVisibility } from '@/utils/form'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup () {
    return {
      capitalize
    }
  },
  data () {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'eye' : 'eye-slash'
    },
  },
  methods: {
    login () {
      let username = this.userEmail
      let password = this.password

      this.$store.dispatch('auth/login', {
        username, password
      })
          .then(() => {
            this.$router.push(
                this.$router.currentRoute.query.redirect || '/dashboard2'
            )
          })
          .catch(error => {
            console.log(error)
            // this.loadingContained(false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Error'),
                icon: 'exclamation-triangle',
                text: this.$t('InvalidCredentials'),
                variant: 'danger'
              },
            })
          })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/auth.scss";
</style>
